import { EmojiEventsOutlined, History } from '@mui/icons-material';
import { ListItemButton } from '@mui/material';
import { styled } from '@mui/system';
import { Chat, UserGroup } from 'components/icons';
import { Link, useLocation } from 'react-router-dom';
import { privateRoute } from 'routes';
import { clsxm } from 'utils/merge';
import { LiveChatWidget } from '@livechat/widget-react';
import { LIVE_CHAT_LICENSE } from 'env';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';

const StyledListItem = styled(ListItemButton)({
  height: 60,
  fontSize: 12,
  fontWeight: 500,
  padding: '10px 0px',
  flexDirection: 'column',
  color: 'var(--color-secondary-main)',
  '&:hover, &.Mui-selected': {
    color: 'var(--color-primary-main)',

    '& svg.text-transparent > path': {
      stroke: 'var(--color-primary-main)',
    },
    '& svg:not(.text-transparent) > path': {
      fill: 'var(--color-primary-main)',
    },
  },
});

const NavItem = ({
  path,
  name,
  icon,
  isFirst,
  isLast,
}: {
  path: string;
  name: string;
  icon?: any;
  isFirst?: boolean;
  isLast?: boolean;
}) => {
  const { pathname } = useLocation();
  const isHome = path === '/';
  return (
    <Link to={path} className='flex-1 m-auto'>
      <StyledListItem
        className={clsxm({
          'rounded-tl-2xl': isFirst,
          'rounded-tr-2xl': isLast,
        })}
        selected={isHome ? pathname === path : pathname.startsWith(path)}
      >
        {icon}
        <span className='whitespace-nowrap'>{name}</span>
      </StyledListItem>
    </Link>
  );
};

const Menu = () => {
  const [visible, setVisible] = useState<'maximized' | 'minimized' | 'hidden'>('hidden');
  const { incId } = useSelector(profileSelector);

  return (
    <div className='flex'>
      <NavItem {...privateRoute.award} isFirst icon={<EmojiEventsOutlined sx={{ fontSize: '26px' }} />} />
      <NavItem {...privateRoute.historyBet} icon={<History sx={{ fontSize: '26px' }} />} />
      <NavItem {...privateRoute.home} icon={<img src={require('assets/icons/Icon.Home.png')} />} />

      <StyledListItem className='flex-1' onClick={() => setVisible('maximized')}>
        <Chat className='text-transparent' />
        <span className='whitespace-nowrap'>CSKH</span>
      </StyledListItem>

      <LiveChatWidget
        license={LIVE_CHAT_LICENSE}
        visibility={visible}
        customerName={incId + ''}
        onVisibilityChanged={({ visibility }) => setVisible(visibility)}
      />

      <NavItem {...privateRoute.account} isLast icon={<UserGroup className='text-transparent' />} />
    </div>
  );
};

export default Menu;
