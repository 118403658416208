import { AddAPhoto } from '@mui/icons-material';
import { CardMedia, Divider, IconButton } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { profileSelector } from 'reducers/profileSlice';
import { fileService } from 'services';
import { formatBalance } from 'utils/common';

const DocumentPersonal = () => {
  const navigate = useNavigate();

  const { username, bankName, balance, bankAccountHolder } = useSelector(profileSelector);

  const { control } = useForm({ mode: 'onChange' });

  const handleChangeFile = (event: any, name: string) => {
    const [file] = event.target.files;
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    fileService.uploadFile(formData).then((fileUrl) => {
      const url = fileUrl as any;
      console.log(url);
    });
  };

  return (
    <div>
      <div className='h-[70px] bg-white -container flex items-center p-4'>
        <IconButton color='inherit' onClick={() => navigate(-1)}>
          <img src={require('assets/icons/Icon.Back.png')} />
        </IconButton>
        <div className='text-sm text-primary-main font-semibold'>HỒ SƠ CÁ CƯỢC</div>
      </div>
      <div className='mt-4 flex space-x-3 items-center'>
        <div className='h-20 w-20 rounded-full'>
          <Controller
            name='avatar'
            defaultValue='https://picsum.photos/200/300'
            control={control}
            rules={{ required: true }}
            render={({ field: { value: avatar }, fieldState: { error } }) => (
              <>
                <input
                  hidden
                  type='file'
                  id='avatar'
                  accept='image/*'
                  onChange={(e) => handleChangeFile(e, 'avatar')}
                />
                <label htmlFor='avatar' className='cursor-pointer' style={error ? { borderColor: 'orangered' } : {}}>
                  <CardMedia
                    image={avatar}
                    className='flex items-center h-20 rounded-full justify-center bg-[#D9D9D9] bg-contain'
                  >
                    {!avatar && (
                      <div className='flex flex-col items-center justify-center space-y-2'>
                        <AddAPhoto sx={{ color: 'silver' }} fontSize='large' />
                        <div className='text-secondary-main text-xs font-medium'>Thêm ảnh</div>
                      </div>
                    )}
                  </CardMedia>
                </label>
              </>
            )}
          />
        </div>
        <div>
          <div className='text-secondary-dark text-sm font-medium'>Tên tài khoản: {username}</div>
          <div className='text-secondary-dark text-sm font-medium'>
            Số dư: <span className='text-primary-main'>{formatBalance(balance)} USD</span>
          </div>
          <div className='text-secondary-dark text-sm font-medium'>
            Kiểm tra số tiền: <span className='text-primary-main'>{formatBalance(balance)} USD</span>
          </div>
        </div>
      </div>

      <div className='bg-[#FF91A4] rounded-md mt-6 text-white text-sm font-medium'>
        <div className='flex items-center justify-between p-4'>
          <div className='whitespace-nowrap'>Tài khoản:</div>
          <div className='text-right'>{bankName}</div>
        </div>
        <Divider sx={{ borderColor: '#FFBBC7' }} />
        <div className='flex items-center justify-between p-4'>
          <div className='whitespace-nowrap'>Tên thật:</div>
          <div className='text-right'>{bankAccountHolder}</div>
        </div>
        <Divider sx={{ borderColor: '#FFBBC7' }} />
        <div className='flex items-center justify-between p-4'>
          <div className='whitespace-nowrap'>Tiền tệ:</div>
          <div className='text-right'>USD</div>
        </div>
      </div>
    </div>
  );
};

export default DocumentPersonal;
