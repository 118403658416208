import { AddAPhoto } from '@mui/icons-material';
import { CardMedia, Grid, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { profileSelector, signIn } from 'reducers/profileSlice';
import { store } from 'reducers/store';
import { authService, fileService } from 'services';
import { formatBalance } from 'utils/common';

const HOME_LINKS = [
  {
    img: require('assets/icons/Icon.Vector.png'),
    content: 'Chi tiết',
    contentMore: 'quỹ',
    color: '#765ED6',
    link: '/account/fund',
  },
  {
    img: require('assets/icons/Icon.Vector-1.png'),
    content: 'Hồ sơ',
    contentMore: 'cá cược',
    color: '#4AAD76',
    link: '/history-bet',
  },
  {
    img: require('assets/icons/Icon.Vector-2.png'),
    content: 'Hồ sơ',
    contentMore: 'cá nhân',
    color: '#F7AA40',
    link: '/account/document-personal',
  },
  {
    img: require('assets/icons/Icon.Vector-3.png'),
    content: 'Tài khoản',
    contentMore: 'ngân hàng',
    color: '#7AD0F3',
    link: '/account/management-bank',
  },
  {
    img: require('assets/icons/Icon.Vector-4.png'),
    content: 'Quản lý',
    contentMore: 'tiền',
    color: '#439DF7',
    link: '/account/transfer-money',
  },
  {
    img: require('assets/icons/Icon.Vector-5.png'),
    content: 'Mật khẩu',
    contentMore: 'đăng nhập',
    color: '#FF6A84',
    link: '/account/change-password',
  },
  {
    img: require('assets/icons/Icon.Vector-6.png'),
    content: 'Mật khẩu',
    contentMore: 'rút tiền',
    color: '#FFA9C6',
    link: '',
  },
  {
    img: require('assets/icons/Icon.Vector-7.png'),
    content: 'Cài đặt',
    contentMore: 'ngôn ngữ',
    color: '#378459',
    link: '',
  },
  {
    img: require('assets/icons/Icon.Vector-8.png'),
    content: 'Dịch vụ',
    contentMore: 'KH trực tuyến',
    color: '#573FB8',
    link: '',
  },
];

const Account = () => {
  const navigate = useNavigate();

  const { username, balance, refId } = useSelector(profileSelector);

  useQuery(['authService.getProfile'], () => authService.getProfile(), {
    onSuccess(data) {
      store.dispatch(signIn(data));
    },
  });

  const { control } = useForm({ mode: 'onChange' });

  const handleChangeFile = (event: any, name: string) => {
    const [file] = event.target.files;
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);
    fileService.uploadFile(formData).then((fileUrl) => {
      const url = fileUrl as any;
      console.log(url);
    });
  };

  return (
    <div>
      <div className='h-[70px] bg-white -container flex items-center p-4'>
        <IconButton color='inherit' onClick={() => navigate(-1)}>
          <img src={require('assets/icons/Icon.Back.png')} />
        </IconButton>
        <div className='text-sm text-primary-main font-semibold uppercase'>Tài khoản cá nhân</div>
      </div>
      <div className='mt-4 flex space-x-3 items-center'>
        <div className='h-20 w-20 rounded-full'>
          <Controller
            name='avatar'
            defaultValue='https://picsum.photos/200/300'
            control={control}
            rules={{ required: true }}
            render={({ field: { value: avatar }, fieldState: { error } }) => (
              <>
                <input
                  hidden
                  type='file'
                  id='avatar'
                  accept='image/*'
                  onChange={(e) => handleChangeFile(e, 'avatar')}
                />
                <label htmlFor='avatar' className='cursor-pointer' style={error ? { borderColor: 'orangered' } : {}}>
                  <CardMedia
                    image={avatar}
                    className='flex items-center h-20 rounded-full justify-center bg-[#D9D9D9] bg-contain'
                  >
                    {!avatar && (
                      <div className='flex flex-col items-center justify-center space-y-2'>
                        <AddAPhoto sx={{ color: 'silver' }} fontSize='large' />
                        <div className='text-secondary-main text-xs font-medium'>Thêm ảnh</div>
                      </div>
                    )}
                  </CardMedia>
                </label>
              </>
            )}
          />
        </div>
        <div>
          <div className='text-secondary-dark text-sm font-medium'>Tên tài khoản: {username}</div>
          <div className='text-secondary-dark text-md font-medium'>
            Số dư: <span className='text-primary-main'>{formatBalance(balance)} USD</span>
          </div>
          <div className='text-secondary-dark text-sm font-medium'>
            Kiểm tra số tiền: <span className='text-primary-main'>{formatBalance(balance)} USD</span>
          </div>
          <div className='text-secondary-dark text-sm font-medium'>
            Mã giới thiệu: <span className='text-primary-main'>{refId}</span>
          </div>
        </div>
      </div>

      <Grid container columnSpacing={7} rowSpacing={3} className='mt-1'>
        {HOME_LINKS.map((item, index) => (
          <Grid item xs={4} className='flex flex-col items-center' key={index}>
            <Link to={item.link} className='w-full'>
              <div className='bg-[#FFEFF5] h-[80px] flex justify-center items-center rounded-[20px]'>
                <div
                  style={{ backgroundColor: item.color }}
                  className='h-[35px] w-[35px] flex justify-center items-center rounded-full'
                >
                  <img src={item.img} />
                </div>
              </div>
            </Link>
            <div className='text-center text-xs text-secondary-dark font-medium mt-2'>
              <div>{item.content}</div>
              <div>{item.contentMore}</div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Account;
