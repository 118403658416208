import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from 'components/common';
import { DateTime } from 'luxon';
import { Fragment } from 'react';
// import { useQuery } from 'react-query';
import { transactionService } from 'services';
import { formatBalance } from 'utils/common';

const TabWithdraw = () => {
  const { data, isFetching } = useQuery(['transactionService.getWithdraw'], () => transactionService.getWithdraw());

  return (
    <Spinner loading={isFetching}>
      <TableContainer>
        <Table>
          <TableHead className='bg-[#d5d5d5b3]'>
            <TableRow>
              <TableCell>Thời gian</TableCell>
              <TableCell align='right'>Số tiền</TableCell>
              <TableCell align='right'>Trạng thái</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.withdrawals.map((item) => {
              const isNote = Boolean(item.note);
              return (
                <Fragment key={item.id}>
                  <TableRow>
                    <TableCell className={isNote ? 'border-b-0' : ''}>
                      {DateTime.fromISO(item.createdAt).toFormat('HH:ss dd/MM/yyyy')}
                    </TableCell>
                    <TableCell className={isNote ? 'border-b-0' : ''} align='right'>
                      <div className='text-success'>{formatBalance(item.amount)}</div>
                    </TableCell>
                    <TableCell className={isNote ? 'border-b-0' : ''} align='right'>
                      {item.status === 'APPROVED' ? (
                        <div className='text-lime'>Đã rút</div>
                      ) : item.status === 'REJECTED' ? (
                        <div className='text-error'>Từ chối</div>
                      ) : (
                        <div className='text-neutral'>Chờ</div>
                      )}
                    </TableCell>
                  </TableRow>
                  {isNote && (
                    <TableRow className='mt-[-4px]'>
                      <TableCell className='pt-0' colSpan={3}>
                        Ghi chú: {item.note}
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Spinner>
  );
};
export default TabWithdraw;
