import { Button, Dialog, IconButton, Tab, Tabs } from '@mui/material';
import { useTabs } from 'hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PopupNotification from 'views/Home/popups/PopupNotification';
import PopupWithdraw from './PopupWithdraw';
import TabDeposit from './TabDeposit';
import TabWithdraw from './TabWithdraw';

const Fund = () => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const tabs = [
    { code: 'deposit', label: 'Lịch sử nạp tiền', component: <TabDeposit /> },
    { code: 'withdraw', label: 'Lịch sử rút tiền', component: <TabWithdraw /> },
  ];
  const [activeTab, onTabChange] = useTabs(tabs);

  return (
    <div>
      <div className='h-[70px] bg-white -container flex items-center p-4'>
        <IconButton color='inherit' onClick={() => navigate(-1)}>
          <img src={require('assets/icons/Icon.Back.png')} />
        </IconButton>
        <div className='text-sm text-primary-main font-semibold uppercase'>CHI TIẾT QUỸ</div>
      </div>

      <div className='-container mt-3 flex align-middle flex-col justify-around'>
        <div className='flex justify-around'>
          <Button
            className='text-[10px] bg-white rounded-md border border-[#FFD8DB] w-[40%]'
            onClick={() => setOpen(true)}
          >
            Nạp tiền
          </Button>
          <Button
            className='text-[10px] bg-white rounded-md border border-[#FFD8DB] w-[40%]'
            onClick={() => setOpenWithdraw(true)}
          >
            Rút tiền
          </Button>
        </div>
        <div className='flex justify-around mt-4'>
          <Tabs value={activeTab} onChange={onTabChange} variant='fullWidth' className='w-full'>
            {tabs.map((tab) => (
              <Tab key={tab.code} label={tab.label} value={tab.code} />
            ))}
          </Tabs>
        </div>
        <div className='flex-1 bg-[#f3f3f3] p-[12px]'>
          {tabs.map((tab) => (
            <div key={tab.code} hidden={tab.code !== activeTab} className='mt-[12px]'>
              {tab.component}
            </div>
          ))}
        </div>
      </div>

      <Dialog open={open} fullWidth>
        <PopupNotification onClose={() => setOpen(false)} />
      </Dialog>
      <Dialog open={openWithdraw} fullScreen>
        <PopupWithdraw onClose={() => setOpenWithdraw(false)} />
      </Dialog>
    </div>
  );
};

export default Fund;
