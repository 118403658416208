import { KeyboardDoubleArrowRight } from '@mui/icons-material';
import { Button, Dialog, Grid } from '@mui/material';
import { NoticeRunner, SliceBanner } from 'components';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { profileSelector, signOut } from 'reducers/profileSlice';
import { privateRoute } from 'routes';
import PopupConfirm from './popups/PopupConfirm';
import { LiveChatWidget, useWidgetIsReady } from '@livechat/widget-react';
import { LIVE_CHAT_LICENSE } from 'env';

const HOME_STEP_CONTENT = [
  {
    title: 'CROW CASINO',
    img: require('assets/icons/Icon.Crown.png'),
    background: 'linear-gradient(180deg, #F7DFBB 0%, #F7AA40 100%)',
  },
  {
    title: 'OKADA CASINO',
    img: require('assets/icons/Icon.Sun.png'),
    background: 'linear-gradient(180deg, #CBC8ED 0%, #8B81F6 100%)',
  },
  {
    title: 'METRO CASINO',
    img: require('assets/icons/Icon.Tram.png'),
    background: 'linear-gradient(180deg, #7AD0F3 0%, #53AFE5 100%)',
  },
];

const HOME_LINKS = [
  {
    img: require('assets/icons/Icon.Dollar-Sign.png'),
    content: 'nạp tiền',
    contentMore: 'rút tiền',
  },
  {
    img: require('assets/icons/Icon.Check-Circle.png'),
    content: 'lịch sử',
    contentMore: 'cá cược',
    link: '/history-bet',
  },
  {
    img: require('assets/icons/Icon.Shield.png'),
    content: 'trung tâm',
    contentMore: 'trợ giúp',
  },
  {
    img: require('assets/icons/Icon.Message-Circle.png'),
    content: 'CSKH',
    contentMore: 'trực tuyến',
  },
];

const Home = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState<'maximized' | 'minimized' | 'hidden'>('hidden');
  const { incId } = useSelector(profileSelector);

  return (
    <div>
      <div className='flex items-center justify-between'>
        <img src={require('assets/images/Auth.Logo.png')} className='h-[69px]' />
        <Button
          variant='contained'
          color='inherit'
          className='min-w-[100px] text-secondary-main font-medium rounded-xl bg-white'
          onClick={() => setOpen(true)}
        >
          Thoát
        </Button>
      </div>

      <div className='mt-2 mb-6'>
        <SliceBanner />
      </div>

      <NoticeRunner />

      <Grid container columnSpacing={2} className='my-3'>
        {HOME_LINKS.map((item, index) => (
          <Grid item xs={3} className='flex flex-col items-center' key={index}>
            {item.link ? (
              <Link to={item.link} className='w-full'>
                <div className='bg-[#FFEFF5] h-[80px] flex justify-center items-center rounded-[20px]'>
                  <img src={item.img} />
                </div>
              </Link>
            ) : (
              <div className='w-full cursor-pointer'>
                <div
                  className='bg-[#FFEFF5] h-[80px] flex justify-center items-center rounded-[20px]'
                  onClick={() => setVisible('maximized')}
                >
                  <img src={item.img} />
                </div>
              </div>
            )}

            <div className='text-center capitalize text-xs text-secondary-dark font-medium mt-2'>
              <div>{item.content}</div>
              <div>{item.contentMore}</div>
            </div>
          </Grid>
        ))}
      </Grid>

      <div className='space-y-2'>
        {HOME_STEP_CONTENT.map((item, index) => (
          <div key={index}>
            <Link to={privateRoute.casino.path}>
              <div className='border flex items-center justify-between bg-[#FFEFF5] p-3 rounded-2xl' key={index}>
                <div className='flex items-center'>
                  <div
                    style={{ background: item.background }}
                    className='flex justify-center items-center h-[50px] w-[50px] rounded-lg'
                  >
                    <img src={item.img} />
                  </div>
                  <div className='ml-6 text-secondary-dark text-xs font-semibold'>{item.title}</div>
                </div>
                <KeyboardDoubleArrowRight color='secondary' />
              </div>
            </Link>
          </div>
        ))}
      </div>
      {useWidgetIsReady() && (
        <LiveChatWidget
          license={LIVE_CHAT_LICENSE}
          visibility={visible}
          customerName={incId + ''}
          onVisibilityChanged={({ visibility }) => setVisible(visibility)}
        />
      )}

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
        <PopupConfirm onClose={() => setOpen(false)} onSuccess={() => dispatch(signOut())} />
      </Dialog>
    </div>
  );
};

export default Home;
