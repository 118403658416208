import { LoadingButton } from '@mui/lab';
import { Container, FormControl, TextField, Typography } from '@mui/material';
import { InputPassword } from 'components/common';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { signIn } from 'reducers/profileSlice';
import { authRoute } from 'routes';
import { authService } from 'services';

const LoginScreen = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: login, isLoading } = useMutation(authService.login, {
    onSuccess: ({ tokens, player }) => {
      enqueueSnackbar('Đăng nhập thành công');
      dispatch(
        signIn({
          accessToken: tokens.access.token,
          refreshToken: tokens.refresh.token,
          ...player,
        }),
      );
    },
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleClickSubmit();
    }
  };

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      login(values);
    })();
  };

  return (
    <div>
      <div className='h-[70px] bg-white flex items-center px-4'>
        <Link to={authRoute.register.url}>
          <img src={require('assets/icons/Icon.Back.png')} />
        </Link>
        <div className='text-sm text-primary-main font-semibold uppercase ml-3'>Đăng nhập</div>
      </div>

      <Container className='mt-8 flex flex-col items-stretch gap-4 relative w-full'>
        <Typography variant='body1' className='text-center font-semibold capitalize text-secondary-dark mt-8'>
          Chào mừng đến với trung tâm giải trí
        </Typography>
        <div className='mx-auto'>
          <img src={require('assets/images/Auth.Logo.png')} alt='logo' />
        </div>
        <Controller
          name='username'
          defaultValue=''
          control={control}
          rules={{
            required: 'Tài khoản không được để trống',
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label className='text-xs text-secondary-dark font-medium'>Tên tài khoản</label>
              <TextField
                {...field}
                fullWidth
                placeholder='Vui lòng nhập tài khoản của bạn'
                sx={{
                  '& fieldset': { border: 'none' },
                }}
                error={!!error}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          name='password'
          defaultValue=''
          control={control}
          rules={{
            required: 'Mật khẩu không được để trống',
          }}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label className='text-xs text-secondary-dark font-medium'>Mật khẩu đăng nhập</label>
              <InputPassword
                {...field}
                fullWidth
                placeholder='Vui lòng nhập mật khẩu của bạn'
                onKeyDown={handleKeyDown}
                error={!!error}
                sx={{
                  '& fieldset': { border: 'none' },
                }}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />
        <LoadingButton
          size='large'
          variant='contained'
          className='min-w-[200px] mt-6 rounded-3xl mx-auto'
          disableElevation={false}
          loading={isLoading}
          onClick={handleClickSubmit}
        >
          Đăng Nhập
        </LoadingButton>
        <div className='text-center'>
          <Link className='font-medium text-xs text-secondary-dark' to={authRoute.register.url}>
            Đăng ký người dùng mới
          </Link>
        </div>
      </Container>

      <div className='mt-12 flex items-center justify-center space-x-10 text-xs text-secondary-dark'>
        <div>Thoả thuận người dùng</div>
        <div>Cài đặt ngôn ngữ</div>
      </div>
    </div>
  );
};

export default LoginScreen;
