import { Grid, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from 'components/common';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { sessionService } from 'services';
import { formatDate } from 'utils/common';
const activeTab = 'LEVEL_5';

export const convertResult = (item: string, index: number) => {
  const number = parseInt(item);
  if ((index === 0 || index === 1) && number < 5) return 'LONG';
  if ((index === 0 || index === 1) && number >= 5) return 'HỔ';
  if ((index === 2 || index === 3) && number < 5) return 'DƯỚI';
  if ((index === 2 || index === 3) && number >= 5) return 'TRÊN';
  if ((index === 4 || index === 5) && number % 2 === 0) return 'CHẴN';
  if ((index === 4 || index === 5) && number % 2 === 1) return 'LẺ';
};

const Award = () => {
  const navigate = useNavigate();
  const { data, isFetching } = useQuery(
    ['sessionService.getSessions', activeTab],
    () => sessionService.getSessions({ limit: 20, zone: activeTab }),
    { keepPreviousData: true },
  );

  return (
    <div>
      <div className='h-[70px] bg-white -container flex items-center p-4'>
        <IconButton color='inherit' onClick={() => navigate(-1)}>
          <img src={require('assets/icons/Icon.Back.png')} />
        </IconButton>
        <div className='text-sm text-primary-main font-semibold'>KẾT QUẢ</div>
      </div>
      <div className='flex items-center my-3'>
        <div className='text-secondary-dark font-medium text-xs'>{formatDate(new Date().toISOString())}</div>
        <div className='text-center flex-1'>
          <div className='text-sm font-semibold text-secondary-dark'>CROW CASINO</div>
        </div>
      </div>
      <Spinner loading={isFetching}>
        <Grid container className='mt-3'>
          <Grid item xs={3}>
            <div className='text-center text-xs bg-[#FF91A4] py-2 px-4 text-white border border-[#FFD8DB]'>
              Thời gian
            </div>
          </Grid>
          <Grid item xs={9}>
            <div className='text-center text-xs bg-[#FF91A4] py-2 px-4 text-white border border-[#FFD8DB]'>
              Số tiền xổ số
            </div>
          </Grid>
          {data?.results.map((item, index) => (
            <Fragment key={item.id}>
              <Grid item xs={3} className='text-center bg-white border border-[#FFD8DB]'>
                <div className='px-3 py-4'>
                  <div className='text-secondary-dark text-[10px]'>{formatDate(item.startTime)}</div>
                  {/* <div className='text-secondary-main text-[10px]'>17:20:00</div> */}
                </div>
              </Grid>
              <Grid item xs={9}>
                <Grid container className='h-full bg-white border border-[#FFD8DB] px-3 py-2'>
                  {item.result.split('').map((item, index) => (
                    <Grid
                      item
                      xs={2}
                      key={index}
                      columnSpacing={0}
                      className='flex flex-col items-center justify-center space-y-1'
                    >
                      <div
                        className='text-white w-[25px] h-[25px] rounded-full text-center'
                        style={{ background: 'linear-gradient(180deg, #FF6A84 0%, #DE3163 100%)' }}
                      >
                        {item}
                      </div>
                      <div className='text-primary-main text-[10px] border border-[#FFD8DB] py-1 px-2'>
                        {convertResult(item, index)}
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Spinner>
    </div>
  );
};

export default Award;
