import {
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Spinner, TableRowEmpty } from 'components/common';
import { useSearch } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { sessionService } from 'services';
export const convertResult = (result: string) => {
  switch (result) {
    case 'LONG':
      return 'LONG';
    case 'HO':
      return 'HỔ';
    case 'TREN':
      return 'TRÊN';
    case 'DUOI':
      return 'DƯỚI';
    case 'CHAN':
      return 'CHẴN';
    case 'LE':
      return 'LẺ';
    default:
      for (let i = 0; i < 10; i++) {
        if (result === 'TRAM_NGAN_' + i) {
          return 'Số ' + i;
        }
      }
      return '';
  }
};

const HistoryBet = () => {
  const navigate = useNavigate();

  const [dataSearch, onSearchChange] = useSearch();

  const { data, isFetching } = useQuery(
    ['sessionService.getBets', dataSearch],
    () => sessionService.getBets(dataSearch),
    {
      keepPreviousData: true,
    },
  );

  const { results = [], page, totalPages } = data ?? {};

  return (
    <div>
      <div className='h-[70px] bg-white -container flex items-center p-4'>
        <IconButton color='inherit' onClick={() => navigate(-1)}>
          <img src={require('assets/icons/Icon.Back.png')} />
        </IconButton>
        <div className='text-sm text-primary-main font-semibold'>LỊCH SỬ</div>
      </div>
      <div className='text-secondary-dark font-semibold text-sm my-3 text-center'>Phiếu cá cược ngay lập tức</div>
      <Spinner loading={isFetching}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Số kỳ</TableCell>
                <TableCell>Lệnh đặt</TableCell>
                <TableCell>Số tiền đặt cược</TableCell>
                <TableCell>Thưởng</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.session.incId}</TableCell>
                  <TableCell>{convertResult(item.gate)}</TableCell>
                  <TableCell>{item.amount}</TableCell>
                  <TableCell>{item.winnings}</TableCell>
                </TableRow>
              ))}
              <TableRowEmpty visible={false} />
            </TableBody>
          </Table>
        </TableContainer>

        <div className='flex justify-center mt-4'>
          <Pagination
            page={page ?? 1}
            count={totalPages}
            onChange={(event, value) => onSearchChange({ page: value })}
          />
        </div>
      </Spinner>
    </div>
  );
};

export default HistoryBet;
