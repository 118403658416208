import { client } from './axios';

const getSessions = (params: any): Promise<SessionPaginateType> => client.get(`v1/sessions`, { params });
const getBets = (params?: PaginateParams): Promise<BetsPaginateType> => client.get(`v1/bets`, { params });
const getInfoStat = ({ id }: { id: string }): Promise<StatInfo> => client.get(`v1/sessions/${id}/stats`);

const sessionService = {
  getSessions,
  getBets,
  getInfoStat,
};
export default sessionService;
