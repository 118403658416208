import { CardMedia, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { InfiniteSlider } from 'components';
import { bannerService } from 'services';

const SliceBanner = () => {
  const { data, isLoading } = useQuery(['bannerService.fetchBanners'], () => bannerService.fetchBanners());
  const { results = [] } = data ?? {};

  return (
    <InfiniteSlider {...{ autoplaySpeed: 10000, autoplay: true, dots: true, arrows: false }}>
      {results?.map((item) => (
        <div key={item.order}>
          <CardMedia image={item.image} className='h-[140px] rounded-lg sm:h-[180px]' />
        </div>
      ))}
      {isLoading && (
        <div className='flex h-[186px] items-center justify-center'>
          <CircularProgress />
        </div>
      )}
    </InfiniteSlider>
  );
};

export default SliceBanner;
