import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, IconButton, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { InputNumber } from 'components/common';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { privateRoute } from 'routes';
import { transactionService } from 'services';

const TransferMoney = () => {
  const navigate = useNavigate();

  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: transferMoney, isLoading } = useMutation(transactionService.transferMoney, {
    onSuccess: () => {
      enqueueSnackbar('Chuyển tiền thành công');
      navigate(privateRoute.account.path);
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      transferMoney(values as TransferMoney);
    })();
  };

  return (
    <>
      <div className='h-[70px] bg-white -container flex items-center p-4'>
        <IconButton color='inherit' onClick={() => navigate(-1)}>
          <img src={require('assets/icons/Icon.Back.png')} />
        </IconButton>
        <div className='text-sm text-primary-main font-semibold'>CHUYỂN TIỀN</div>
      </div>
      <div className='text-secondary-dark font-semibold text-sm my-3 text-center'>Chuyển tiền nội bộ</div>
      <Grid container rowSpacing={2} className='my-4'>
        <Grid item xs={12}>
          <Controller
            name='username'
            defaultValue=''
            control={control}
            rules={{
              required: 'Tài khoản không được để trống',
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth>
                <label className='text-xs text-secondary-dark font-medium'>Tên tài khoản</label>
                <TextField
                  {...field}
                  fullWidth
                  placeholder='Tên tài khoản'
                  sx={{
                    '& .MuiInputBase-root': {
                      color: 'var(--color-text-primary)',
                      background: '#FFFC',
                      borderRadius: '10px',
                    },
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name='amount'
            defaultValue=''
            rules={{
              required: 'Số tiền rút không được để trống',
              min: { value: 1, message: 'Số tiền rút phải lớn hơn 0' },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth>
                <label className='text-xs font-semibold'>Số tiền</label>
                <TextField
                  {...field}
                  fullWidth
                  placeholder='Nhập số tiền'
                  InputProps={{
                    inputComponent: InputNumber,
                    inputProps: { maxLength: 12 },
                  }}
                  sx={{
                    '& .MuiInputBase-root': {
                      color: 'var(--color-text-primary)',
                      background: '#FFFC',
                      borderRadius: '10px',
                    },
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name='note'
            defaultValue=''
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth>
                <label className='mb-2 text-xs font-semibold'>Nội dung chuyển</label>
                <TextField
                  {...field}
                  color='warning'
                  placeholder='Nhập nội dung'
                  sx={{
                    '& .MuiInputBase-root': {
                      color: 'var(--color-text-primary)',
                      background: '#FFFC',
                      borderRadius: '10px',
                    },
                  }}
                  error={!!error}
                  helperText={error?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <div className='flex mt-10'>
        <LoadingButton
          size='large'
          className='rounded-full mx-auto min-w-[200px]'
          onClick={handleClickSubmit}
          loading={isLoading}
          variant='contained'
        >
          Xác nhận
        </LoadingButton>
      </div>
    </>
  );
};

export default TransferMoney;
