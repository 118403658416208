import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { transactionService } from 'services';
import { convertResult } from 'views/HistoryBet/HistoryBet';

type PopupProps = PopupController & {
  amount: number;
  gate: string;
  incId: string;
};

const PopupBet = ({ amount, onClose, onSuccess, gate, incId }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: createBet, isLoading } = useMutation(transactionService.createBet, {
    onSuccess: () => {
      enqueueSnackbar('Đặt lệnh thành công');
      onSuccess?.();
      onClose();
    },
  });

  const handleClickSubmit = () => {
    const payload = {
      zone: 'LEVEL_5',
      game: 'ONE_TO_FIVE',
      amount,
      gate,
    };
    createBet(payload);
  };

  return (
    <>
      <DialogTitle className='text-center'>XÁC NHẬN</DialogTitle>
      <Divider />
      <DialogContent className='text-left text-xs space-y-6 text-secondary-dark'>
        <div className='flex items-center'>
          <div className='min-w-[120px]'>Name:</div>
          <div>{incId}</div>
        </div>
        <div className='flex items-center space-x-2'>
          <div className='min-w-[120px] text-secondary-dark'>
            Tổng số cược: <span className='text-primary-main font-semibold'>{convertResult(gate)}</span>
          </div>
          <div>
            Tổng số tiền: <span className='text-primary-main font-semibold'>{amount}</span>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <LoadingButton variant='contained' color='inherit' fullWidth onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' fullWidth onClick={handleClickSubmit} loading={isLoading}>
          OK
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupBet;
