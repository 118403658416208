import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent } from '@mui/material';

type PopupProps = PopupController;

const PopupConfirm = ({ onClose, onSuccess }: PopupProps) => {
  return (
    <>
      <DialogContent className='text-center text-secondary-dark'>Bạn có muốn đăng xuất ?</DialogContent>

      <DialogActions className='mx-auto'>
        <LoadingButton variant='contained' color='inherit' className='min-w-[120px]' onClick={onClose}>
          Hủy
        </LoadingButton>
        <LoadingButton variant='contained' className='min-w-[120px]' onClick={onSuccess}>
          OK
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupConfirm;
