import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent } from '@mui/material';

type PopupProps = PopupController;

const PopupNotification = ({ onClose }: PopupProps) => {
  return (
    <>
      <DialogContent className='text-center text-secondary-dark'>
        Vui lòng liên hệ CSKH để được hướng dẫn nạp tiền
      </DialogContent>

      <DialogActions className='mx-auto'>
        <LoadingButton variant='contained' className='min-w-[120px]' onClick={onClose}>
          Đồng ý
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default PopupNotification;
