import { LoadingButton } from '@mui/lab';
import { Autocomplete, FormControl, IconButton, TextField } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { profileSelector, signIn } from 'reducers/profileSlice';
import { transactionService } from 'services';
import BankList from './BankList.json';

const ManagementBank = () => {
  const navigate = useNavigate();

  const { control, setValue, handleSubmit } = useForm({ mode: 'onChange' });

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { id, bankAccountHolder, bankAccountNumber, bankName, totalChangeBank } = useSelector(profileSelector);

  const { mutate: updateBank, isLoading } = useMutation(transactionService.updateBank, {
    onSuccess: (data) => {
      dispatch(signIn(data));
      enqueueSnackbar('Liên kết ngân hàng thành công');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateBank({
        playerId: id,
        body: values,
      });
    })();
  };

  return (
    <>
      <div className='h-[70px] bg-white -container flex items-center p-4'>
        <IconButton color='inherit' onClick={() => navigate(-1)}>
          <img src={require('assets/icons/Icon.Back.png')} />
        </IconButton>
        <div className='text-sm text-primary-main font-semibold'>QUẢN LÝ THẺ NGÂN HÀNG</div>
      </div>
      <div className='text-secondary-dark font-semibold text-sm my-3 text-center'>Cài đặt thông tin ngân hàng</div>
      <div className='space-y-4 flex flex-col items-stretch'>
        <Controller
          control={control}
          name='bankName'
          defaultValue={bankName}
          rules={{
            required: 'Ngân hàng không được để trống',
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label className='mb-2 text-xs font-semibold'>Tên ngân hàng</label>
              <Autocomplete
                disabled={totalChangeBank ? totalChangeBank >= 2 : false}
                value={value}
                onInputChange={(event, value) => {
                  onChange({ target: { value } });
                  setValue('bankName', value);
                }}
                onChange={(event, option) => {
                  onChange({ target: { value: option?.label ?? option } });
                  setValue('bankName', option?.label ?? option);
                }}
                options={BankList.banks}
                getOptionLabel={(option) => option.label ?? option}
                isOptionEqualToValue={(option, value) => option === value || option.label === value}
                classes={{
                  option: 'text-sm min-h-[40px]',
                }}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    color='warning'
                    sx={{
                      '& .MuiInputBase-root': {
                        color: 'var(--color-text-primary)',
                        background: '#FFFC',
                        borderRadius: '10px',
                      },
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name='bankAccountNumber'
          defaultValue={bankAccountNumber}
          rules={{
            required: 'Số thẻ ngân hàng không được để trống',
          }}
          disabled={totalChangeBank ? totalChangeBank >= 2 : false}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label className='mb-2 text-xs font-semibold'>Số thẻ ngân hàng</label>
              <TextField
                {...field}
                color='warning'
                sx={{
                  '& .MuiInputBase-root': {
                    color: 'var(--color-text-primary)',
                    background: '#FFFC',
                    borderRadius: '10px',
                  },
                }}
                error={!!error}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name='bankAccountHolder'
          defaultValue={bankAccountHolder}
          rules={{
            required: 'Tên chủ thẻ không được để trống',
          }}
          disabled={totalChangeBank ? totalChangeBank >= 2 : false}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth>
              <label className='mb-2 text-xs font-semibold'>Tên chủ thẻ</label>
              <TextField
                {...field}
                color='warning'
                sx={{
                  '& .MuiInputBase-root': {
                    color: 'var(--color-text-primary)',
                    background: '#FFFC',
                    borderRadius: '10px',
                  },
                }}
                error={!!error}
                helperText={error?.message}
              />
            </FormControl>
          )}
        />
        <div className='text-sm'>* Quý khách vui lòng điền đúng thông tin</div>
      </div>
      <div className='flex mt-10'>
        <LoadingButton
          disabled={totalChangeBank ? totalChangeBank >= 2 : false}
          size='large'
          className='rounded-full mx-auto min-w-[200px]'
          loading={isLoading}
          onClick={handleClickSubmit}
          variant='contained'
        >
          Lưu thông tin
        </LoadingButton>
      </div>
    </>
  );
};

export default ManagementBank;
