import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { TableRowEmpty } from 'components/common';
import { useNavigate } from 'react-router-dom';

const HistoryBet = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className='h-[70px] bg-white -container flex items-center p-4'>
        <IconButton color='inherit' onClick={() => navigate(-1)}>
          <img src={require('assets/icons/Icon.Back.png')} />
        </IconButton>
        <div className='text-sm text-primary-main font-semibold'>HỒ SƠ CÁ CƯỢC</div>
      </div>
      <div className='text-secondary-dark font-semibold text-sm my-3 text-center'>Phiếu cá cược ngay lập tức</div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Thời gian</TableCell>
              <TableCell>Số nét</TableCell>
              <TableCell>Số tiền đặt cược</TableCell>
              <TableCell>Số tiền thắng</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 2, 3].map((item) => (
              <TableRow>
                <TableCell>0 tổng số ngày</TableCell>
                <TableCell>0</TableCell>
                <TableCell>0</TableCell>
                <TableCell>0</TableCell>
              </TableRow>
            ))}
            <TableRowEmpty visible={false} />
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default HistoryBet;
