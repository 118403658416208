import { LoadingButton } from '@mui/lab';
import { FormControl, Grid, IconButton } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { InputPassword } from 'components/common';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { authService } from 'services';

const ChangePassword = () => {
  const navigate = useNavigate();

  const { control, watch, handleSubmit } = useForm({ mode: 'onChange' });

  const { newPassword } = watch();
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: changePassword, isLoading } = useMutation(authService.changePassword, {
    onSuccess: () => {
      enqueueSnackbar('Đổi mật khẩu thành công');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      changePassword(values);
    })();
  };

  return (
    <>
      <div className='h-[70px] bg-white -container flex items-center p-4'>
        <IconButton color='inherit' onClick={() => navigate(-1)}>
          <img src={require('assets/icons/Icon.Back.png')} />
        </IconButton>
        <div className='text-sm text-primary-main font-semibold'>THAY ĐỔI MẬT KHẨU ĐĂNG NHẬP</div>
      </div>
      <Grid container rowSpacing={2} className='my-4'>
        <Grid item xs={12}>
          <Controller
            name='oldPassword'
            defaultValue=''
            control={control}
            rules={{
              required: 'Mật khẩu không được để trống',
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth>
                <label className='text-xs font-semibold'>Mật khẩu cũ</label>
                <InputPassword
                  {...field}
                  fullWidth
                  placeholder='Vui lòng nhập mật khẩu cũ'
                  color='warning'
                  error={!!error}
                  helperText={error?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='newPassword'
            defaultValue=''
            control={control}
            rules={{
              required: 'Mật khẩu không được để trống',
              minLength: { value: 6, message: 'Mật khẩu có ít nhất 6 ký tự' },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth>
                <label className='text-xs font-semibold'>Mật khẩu mới</label>
                <InputPassword
                  {...field}
                  fullWidth
                  placeholder='Vui lòng nhập mật khẩu mới'
                  color='warning'
                  error={!!error}
                  helperText={error?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name='passwordConfirm'
            defaultValue=''
            control={control}
            rules={{
              required: 'Mật khẩu không hợp lệ',
              validate: {
                match: (value) => value === newPassword || 'Mật khẩu không khớp',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl fullWidth>
                <label className='text-xs font-semibold'>Nhập lại mật khẩu mới</label>
                <InputPassword
                  {...field}
                  fullWidth
                  placeholder='Vui lòng nhập lại mật khẩu mới'
                  color='warning'
                  error={!!error}
                  helperText={error?.message}
                />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <div className='flex mt-10'>
        <LoadingButton
          size='large'
          className='rounded-full mx-auto min-w-[200px]'
          onClick={handleClickSubmit}
          loading={isLoading}
          variant='contained'
        >
          Xác nhận
        </LoadingButton>
      </div>
    </>
  );
};

export default ChangePassword;
