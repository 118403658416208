import { Paper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { systemService } from 'services';

const NoticeRunner = () => {
  const { data } = useQuery(['systemService.fetchSystem'], () => systemService.fetchSystem());

  return (
    <Paper className='-container mt-3 px-3 py-1 bg-[#F48FB1] rounded-none text-white'>
      <div className='text-center text-xs'>
        <span>{data?.notification || ''}</span>
      </div>
    </Paper>
  );
};

export default NoticeRunner;
