import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Paper, TextField } from '@mui/material';
import { CloseButton } from 'components/common';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { profileSelector } from 'reducers/profileSlice';
import { queryClient, transactionService } from 'services';

type PopupProps = PopupController & {};

const PopupBanking = ({ onClose }: PopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { id: playerId } = useSelector(profileSelector);

  const { control, handleSubmit } = useForm({ mode: 'onChange' });

  const { mutate: updateBank, isLoading } = useMutation(transactionService.updateBank, {
    onSuccess: () => {
      queryClient.invalidateQueries(['authService.getProfile']);
      enqueueSnackbar('Liên kết ngân hàng thành công');
    },
  });

  const handleClickSubmit = () => {
    handleSubmit((values) => {
      updateBank({
        playerId,
        body: values,
      });
    })();
  };

  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle className='text-white'>Ngân hàng</DialogTitle>
      <DialogContent className='flex flex-col p-0'>
        {/* <img src={require('assets/images/User.BankLinking.png')} className='h-20 w-20 mx-auto' /> */}
        <Paper className='flex flex-col space-y-6 rounded-[24px] p-[16px] m-[12px]'>
          <Controller
            control={control}
            name='bankName'
            defaultValue=''
            rules={{
              required: 'Ngân hàng không được để trống',
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                variant='standard'
                label='Ngân hàng'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='bankAccountNumber'
            defaultValue=''
            rules={{
              required: 'Số tài khoản không được để trống',
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                variant='standard'
                label='Số tài khoản'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            control={control}
            name='bankAccountHolder'
            defaultValue=''
            rules={{
              required: 'Chủ tài khoản không được để trống',
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                variant='standard'
                label='Chủ tài khoản'
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <div className='text-secondary-main'>* Quý khách vui lòng điền đúng thông tin</div>

          <DialogActions className='flex-1 items-end'>
            <LoadingButton
              variant='contained'
              color='primary'
              className='w-[200px] rounded-full'
              loading={isLoading}
              onClick={handleClickSubmit}
            >
              Xác nhận
            </LoadingButton>
          </DialogActions>
        </Paper>
      </DialogContent>
    </>
  );
};

export default PopupBanking;
