import { Container, Dialog } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AppFooter } from 'containers';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { profileSelector, signIn } from 'reducers/profileSlice';
import { store } from 'reducers/store';
import { systemSelector } from 'reducers/systemSlice';
import { authRoute, privateRoute } from 'routes';
import { authService } from 'services';
import { Socket } from 'utils/socket';
import { PopupAlert } from 'views/Home/components';

const PrivateLayout = () => {
  const navigator = useNavigate();
  const { isReady } = useSelector(systemSelector);
  const { isLoggedIn } = useSelector(profileSelector);

  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  useEffect(() => {
    console.log('2222', 2222);
    getProfile();
  }, []);

  const getProfile = async () => {
    const profile = await authService.getProfile();
    console.log('profile', profile);
    if (profile) {
      store.dispatch(signIn(profile));
    }
  };

  useEffect(() => {
    const socket = new Socket();
    if (!isLoggedIn) {
      socket.disconnect();
      navigator(authRoute.login.url, { replace: true });
    } else {
      socket.connect();
      socket.instance()?.on('NOTIFICATION', (data: NotificationType) => {
        setOpenAlert(true);
        setMessage(data.content);
      });
    }
  }, [isLoggedIn, navigator]);

  return (
    <Container component='main' className='pb-[60px] bg-primary-light'>
      {isReady ? (
        <Routes>
          {Object.values(privateRoute).map(({ path, component: Element }) => (
            <Route key={path} path={path} element={<Element />} />
          ))}
          <Route path='*' element={<Navigate to={privateRoute.home.path} />} />
        </Routes>
      ) : (
        <></>
      )}
      <AppFooter />

      <Dialog open={openAlert} onClose={() => setOpenAlert(false)}>
        <PopupAlert onClose={() => setOpenAlert(false)} message={message} />
      </Dialog>
    </Container>
  );
};

export default PrivateLayout;
