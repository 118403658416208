import { Account } from 'views/Account';
import {
  ChangePassword,
  DocumentBet,
  DocumentPersonal,
  Fund,
  ManagementBank,
  TransferMoney,
} from 'views/Account/components';
import { Award } from 'views/Award';
import { Casino } from 'views/Casino';
import { HistoryBet } from 'views/HistoryBet';
import { Home } from 'views/Home';

const privateRoute = {
  home: {
    path: '/',
    name: '',
    component: Home,
  },
  award: {
    path: '/award',
    name: 'Kết quả',
    component: Award,
  },
  historyBet: {
    path: '/history-bet',
    name: 'Lịch sử',
    component: HistoryBet,
  },
  casino: {
    path: '/casino',
    name: 'Casino',
    component: Casino,
  },
  account: {
    path: '/account',
    name: 'Tài Khoản',
    component: Account,
  },
  accountFund: {
    path: '/account/fund',
    name: 'Chi tiết quỹ',
    component: Fund,
  },
  transferMoney: {
    path: '/account/transfer-money',
    name: 'Chuyển tiền nội bộ',
    component: TransferMoney,
  },
  accountDocumentBet: {
    path: '/account/document-bet',
    name: 'Hồ sơ cá cược',
    component: DocumentBet,
  },
  accountDocumentPersonal: {
    path: '/account/document-personal',
    name: 'Hồ sơ cá nhân',
    component: DocumentPersonal,
  },
  accountBank: {
    path: '/account/management-bank',
    name: 'Quản lý thẻ ngân hàng',
    component: ManagementBank,
  },
  accountChangePassword: {
    path: '/account/change-password',
    name: 'Thay đổi mật khẩu đăng nhập',
    component: ChangePassword,
  },
};

export default privateRoute;
